import Vuex from 'vuex'

const actions={

}
const mutations={}
const state={
    sum:0
}
// 创建store
export default new Vuex.Store({
    actions:actions,
    mutations:mutations,
    state:state,
})
