<template>
<div class="first1">
  <div class="first2">
 <img src="./assets/a.png" alt="">
  
    <div>
      <p></p>
    </div>
  </div>
</div>

  <!-- <List/> -->
</template>

<script>



export default {
  name: 'App',
  components: {
   
   
   
  
  },
  mounted(){
    console.log('APP',this);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.first1{
  width: 100%;
  height: 100%;
}
.first2{
  width: 50%;
  height: 100%;
  margin: 0 auto;
}
.first2 img{
  width: 100%;
  height: 100%;
}

</style>
